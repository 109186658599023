import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Dimensions, TextInput, TouchableOpacity } from 'react-native';
import { Colors } from '~/constants/Colors';
import { PrimaryText } from '~/components/Common/PrimaryText';
import * as FaIcons from "react-icons/fa";


const { height, width } = Dimensions.get('window')


export const AuditDetailItemCard = ({
  item,
  index,
  onQuantityChanged,
  removeItem,
  name
}) => {

  const [qty, setQty] = useState(item.newQty)

  useEffect(() => {
    setQty(item.newQty)
  }, [item])

  function subtract() {
    if (qty > 0) {
      item.newQty--
      setQty(qty - 1)
    }
    onQuantityChanged(item, index)
  }

  function add() {
    item.newQty++
    setQty(qty + 1)
    onQuantityChanged(item, index)
  }

  function quantityChanged(text) {
    const trimmed = text.replace(/\D/g, '')
    item.newQty = Number(trimmed)
    setQty(Number(trimmed))
    onQuantityChanged(item, index)
  }

  let style = styles(false)

  return (
    <View style={style.card}>
      <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
        <TouchableOpacity onPress={removeItem} style={{ marginRight: 12 }}>
          <FaIcons.FaWindowClose color={Colors.primary.gray} size={20} style={{ marginLeft: 20 }} />
        </TouchableOpacity>
        <View style={{ flex: 1, opacity: item?.approved ? 0.6 : 1 }}>
          <PrimaryText fontSize={14} fontWeight={400} numberOfLines={1}>{name}</PrimaryText>
          <PrimaryText fontSize={12} fontWeight={400} style={{ marginTop: 4 }}>{`Original Qty: ${item.originalQty}`}</PrimaryText>
        </View>
      </View>
      {item.approved ?
        <PrimaryText fontSize={12} fontWeight={400} style={{ marginTop: 4 }}>{`Approved Qty: ${item.newQty}`}</PrimaryText>
        :
        <View style={style.quantityContainer}>
          <TouchableOpacity onPress={subtract}>
            <FaIcons.FaMinus color={Colors.primary.red} size={20} />
          </TouchableOpacity>
          <TextInput
            value={qty.toString()}
            onChangeText={(text) => quantityChanged(text)}
            style={{ height: 23, width: width * 0.12, textAlign: 'center' }}
            keyboardType={'number-pad'}
          />
          <TouchableOpacity onPress={add}>
            <FaIcons.FaPlus color={Colors.primary.red} size={20} />
          </TouchableOpacity>

        </View>
      }
    </View>
  )
}


export const styles = darkMode => StyleSheet.create({
  card: {
    marginHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1,
    backgroundColor: Colors.primary.white
  },
  quantityContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  }

});
