import React, { useEffect, useMemo, useState } from "react";
import { StyleSheet, View, ActivityIndicator, FlatList } from "react-native";
import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { CustomInput } from "components/Inputs/CustomInput";
import { useCompany } from "context/CompanyContext";
import { useParts } from "context/PartsContext";
import { MinMaxCard } from "components/Cards/MinMaxCard";
import { PrimaryText } from "components/Common/PrimaryText";
import { useNavigate } from "react-router-dom";
import { Links } from "constants/Links";
import { formatMinMaxReport } from 'utils/exports-helper';
import { firestore } from "utils/firebase";
import { usePersistState } from "context/usePersistState";


export function MinMaxReport() {

  const navigate = useNavigate()

  const { setSelectedItem, parts, locationParts } = useParts()
  const { locations, isDTools, company } = useCompany()

  const [filtered, setFiltered] = usePersistState(
    "PARTS_minMaxParts",
    null
  );
  const [search, setSearch] = useState(null)
  const [loading, setLoading] = useState(true)


  const items = useMemo(() => {
    if (locationParts?.length && parts && locations) {
      const min = [];
      const max = [];
      locationParts.forEach((part) => {
        //min max
        part.onOrderQty = 0;
        if (part.minStockQty && part.minStockQty >= part.locationQty) {
          const locationName = locations.find(x => x.id === part.locationID)?.name
          part.locationName = locationName
          min.push(part);
        }
        if (part.maxStockQty && part.maxStockQty <= part.locationQty) {
          const locationName = locations.find(x => x.id === part.locationID)?.name
          part.locationName = locationName
          max.push(part);
        }

      });

      let tempMinMax = min.concat(max);
      tempMinMax = tempMinMax.filter(
        (item, pos) => tempMinMax.indexOf(item) === pos
      );
      tempMinMax.sort((a, b) => a.manufacturer.toUpperCase().localeCompare(b.manufacturer.toUpperCase() || a.model.toUpperCase() - b.model.toUpperCase()))
      return tempMinMax.filter((x) => x?.active && !x?.archived);
    } else {
      return []
    }
  }, [locationParts, parts, locations]);

  useEffect(() => {
    if (filtered?.length) {
      setLoading(false)
    }
  }, [filtered])

  useEffect(() => {
    if (search) {
      const temp = items
        .filter(x =>
          x.locationName.toLowerCase().includes(search.toLowerCase())
          || x.model.toLowerCase().includes(search.toLowerCase())
          || x.manufacturer.toLowerCase().includes(search.toLowerCase())
        )

      setFiltered(temp)
    } else {
      setFiltered(items)
    }
  }, [search, items])

  useEffect(() => {
    if (isDTools || !filtered) return
    let subs = []
    filtered.forEach(item => {
      // item.onOrderQty = 0
      let sub = firestore.collection('companies').doc(company.id).collection('purchaseOrders')
        .where('itemListId', '==', item.id)
        .where('customerId', '==', '')
        .where('receivedInFull', '==', false)
        .onSnapshot(querySnapshot => {
          // console.log(querySnapshot.size, item.id)
          let total = 0;
          querySnapshot.forEach(doc => {
            const data = doc.data();
            total += data.quantity;
          })
          item.onOrderQty = total
        }, err => {
          console.log(err)
        })
      subs.push(sub)
    })

    return () => {
      subs.forEach(sub => sub())
    }

  }, [filtered])

  function itemSelected(item) {
    setSelectedItem(item)
    navigate(Links.Inventory)
  }


  return (
    <View style={styles.container}>
      <Header
        title={'Min/Max Report'}
        exportData={formatMinMaxReport(filtered)}
      />
      {loading ?
        <ActivityIndicator animating size={'large'} style={styles.activity} color={Colors.primary.lightBlue} />
        :
        <>
          <CustomInput
            placeholder={'Filter By Item or Location'}
            style={{ marginHorizontal: 12 }}
            onChange={setSearch}
            value={search}
          />
          <View style={styles.detailLabelContainer}>
            <View style={styles.leftContainer}>
              <PrimaryText fontSize={13} fontWeight={500} style={{ flex: 1 }} ></PrimaryText>
              <PrimaryText fontSize={12} fontWeight={400} style={{ flex: 1 }} ></PrimaryText>
            </View>
            <View style={{ flexDirection: 'row' }}>

              <PrimaryText fontSize={13} fontWeight={400} style={styles.text}>Min</PrimaryText>
              <PrimaryText fontSize={13} fontWeight={400} style={styles.text}>Qty</PrimaryText>
              <PrimaryText fontSize={13} fontWeight={400} style={styles.text}>Max</PrimaryText>
              {!isDTools && <PrimaryText fontSize={13} fontWeight={400} style={[styles.text, { width: 90 }]}>On Order</PrimaryText>}
            </View>
          </View>
          <FlatList
            style={{ flex: 1 }}
            contentContainerStyle={{ backgroundColor: Colors.primary.white }}
            data={filtered}
            keyExtractor={item => item.locationPartId}
            renderItem={({ item, index }) => {
              return (
                <MinMaxCard
                  item={item}
                  name={item.manufacturer ? item.manufacturer + ': ' + item.model : item.model}
                  location={item?.locationName}
                  showItem
                  onPress={() => itemSelected(item)}
                />
              )
            }}
            ListEmptyComponent={<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <PrimaryText fontSize={20} fontWeight={400}>No items to report</PrimaryText>
            </View>}
          />

        </>
      }

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  activity: {
    flex: 1,
    backgroundColor: Colors.primary.white
  },
  detailLabelContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    marginTop: 12,
    backgroundColor: Colors.primary.gray
  },
  leftContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  text: {
    width: 60,
    textAlign: 'right',
    color: Colors.primary.white
  },

});
