import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Dimensions,
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
  Pressable,
} from "react-native-web";
import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { useCompany } from "context/CompanyContext";
import { PrimaryText } from "components/Common/PrimaryText";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import {
  approveAuditItems,
  completeAudit,
  getAuditItems,
} from "services/audits-service";
import { AuditDetailCard } from "components/Cards/AuditDetailCard";
import { useParts } from "context/PartsContext";
import { cloneDeep } from "lodash";
import { ConfirmModal } from "components/Modals/Confirmation";
import { useAuth } from "context/AuthContext";
import { getItemName, getItemNameFromManufacturer, selectPartForAudit } from "utils/helpers";
import { formatAuditReport } from "utils/exports-helper";
import * as VSIcons from "react-icons/vsc";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import { FullModal } from "components/Modals/FullModal";
import { ModalHeader } from "components/Modals/ModalHeader";
import { ListContainer } from "components/ListContainer";
import { Scanner } from "components/Scanner/Scanner";
import { BiBarcode } from "react-icons/bi";
import { isParentItem, searchParts } from "utils/helpers";
import { ManufacturerCard } from "components/Cards/ManufacturerCard";
import { ModelCard } from "components/Cards/ModelCard";
import { CustomInput } from "components/Inputs/CustomInput";
import { firestore } from "utils/firebase";
import { AuditDetailItemCard } from "components/Cards/AuditDetailItemCard";

const { width, height } = Dimensions.get("window");

export function AuditReport() {
  const navigate = useNavigate();

  const { currentUser } = useAuth();
  const { company, selectedAudit, selectedLocation } = useCompany();
  const { parts, locationParts, manufacturers, selectedItem, setSelectedItem } = useParts();

  const [allParts, setAllParts] = useState(null);
  const [scannedItems, setScannedItems] = useState(null);
  const [filteredItems, setFilteredItems] = useState(null);
  const [sorted, setSorted] = useState(null);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [approveDisabled, setApproveDisabled] = useState(
    selectedAudit?.complete
  );

  //edit audit
  const [showEditingAudit, setShowEditingAudit] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  //items
  const [selectedItems, setSelectedItems] = useState([]);
  const [items, setItems] = useState(manufacturers);
  const [selectedParent, setSelectedParent] = useState(null);
  const [search, setSearch] = useState(null);
  const [showBrowseModal, setShowBrowseModal] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const [scannedItem, setScannedItem] = useState("");





  useEffect(() => {
    // console.log('test', selectedAudit)
    setAllParts(
      locationParts.filter(
        (x) => x.locationID === selectedLocation.id && x.locationQty !== 0
      )
    );
    const subscriber = getAuditItems(company, selectedAudit, setScannedItems);
    return () => subscriber();
  }, []);

  useEffect(() => {
    if (scannedItems) {
      const temp = [];
      scannedItems.forEach((item) => {
        const part = parts.find((x) => x.id === item.partID);
        if (part) {
          const clonedItem = { ...part };
          clonedItem.locationQty = item.originalQty;
          clonedItem.originalQty = item.originalQty
          clonedItem.newQty = item.newQty || 0;
          clonedItem.timeStamp = item.scanTime;
          clonedItem.approved = item.approved;
          temp.push(clonedItem);
        }
      });
      console.log('test', temp)
      setSelectedItems([...temp]);
      setFilteredItems(temp);
    }
  }, [scannedItems]);

  useEffect(() => {
    // console.log('all parts', allParts)
    if (filteredItems && allParts) {
      const temp = filteredItems.sort((a, b) =>
        sortObject(a.lowercaseMfg.toLowerCase(), b.lowercaseMfg.toLowerCase())
      );

      allParts
        .filter((x) => x.active && x.qty !== 0 && x.qty !== undefined)
        .sort((a, b) =>
          sortObject(a.lowercaseMfg.toLowerCase(), b.lowercaseMfg.toLowerCase())
        )
        .forEach((item) => {
          const find = temp.find((x) => x.id === item.partID);
          if (!find) {
            temp.push(item);
          } else {
            find.actualQty = item.locationQty;
          }
        });
      setSorted(temp);
    }
  }, [filteredItems, allParts]);



  useEffect(() => {
    if (sorted) {
      const items = sorted.filter(
        (x) => x?.newQty !== undefined && !x.approved
      );
      setApproveDisabled(
        selectedAudit?.complete || !items || items?.length === 0
      );
      console.log(selectedAudit, items?.length)
    }
  }, [sorted]);

  function sortObject(a, b) {
    if (a === b) return 0;
    return a < b ? -1 : 1;
  }

  function approveAll() {
    setLoading(true);
    setShowConfirmModal(false);
    const items = sorted.filter((x) => x?.newQty !== undefined && !x.approved);
    // console.log('items to approve', items)
    if (items && items.length > 0) {
      approveAuditItems(
        company,
        currentUser,
        selectedAudit,
        locationParts,
        selectedLocation,
        items,
        onSuccess
      );
    }
  }

  function onSuccess() {
    setShowConfirmModal('complete');
    setLoading(false);
  }




  /** START ITEMS */

  //reset items on close browse modal
  useEffect(() => {
    !showBrowseModal && filterItems(manufacturers);
  }, [showBrowseModal]);

  //search or browse parts
  useEffect(() => {
    searchParts(
      search,
      selectedParent,
      items,
      filterItems,
      parts,
      manufacturers,
      showBrowseModal
    );
  }, [search]);

  useEffect(() => {
    if (!selectedParent) {
      filterItems(manufacturers);
      setSearch(null);
    }
  }, [selectedParent]);

  function filterItems(items) {
    setItems(items.filter((item) => item.active && !item.archived));
  }

  function itemSelected(item) {
    selectPartForAudit(
      item,
      selectedLocation,
      parts,
      locationParts,
      setSelectedParent,
      setItems,
      selectedItems,
      setSelectedItems,
      setSearch,
      setShowBrowseModal,
      setShowScanner,
      setSelectedItem
    )
  }

  function itemQtyChanged(item, index) {
    const temp = cloneDeep(selectedItems);
    temp[index] = item;
    setSelectedItems(temp);
  }

  function removeItem(item) {
    const temp = cloneDeep(selectedItems)
    let index = selectedItems.findIndex(x => x.id === item.id)
    temp.splice(index, 1)
    setSelectedItems(temp)
    firestore.collection('companies').doc(company.id).collection('audits').doc(selectedAudit.id).collection('scanned').doc(item.id)
      .delete()
  }
  /** END ITEMS */

  function submitTransaction() {
    setSubmitDisabled(true)
    setLoading(true)



    let perChunk = 200
    let chunks = selectedItems.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

    let promises = []

    chunks &&
      chunks?.length > 0 &&
      chunks.forEach(chunk => {
        const batch = firestore.batch()
        chunk.forEach(item => {
          if (!item.approved) {
            const scannedItem = {
              approved: false,
              manufacturer: item.manufacturer || '',
              model: item.model || '',
              newQty: item.newQty || 0,
              originalQty: item.originalQty || 0,
              partID: item.id,
              scanTime: new Date()
            }

            const scannedDocRef = firestore.collection('companies').doc(company.id).collection('audits').doc(selectedAudit.id).collection('scanned').doc(item.id)
            batch.set(scannedDocRef, scannedItem)
            // 

            // console.log('item id', scannedItem)
          }

        })
        promises.push(batch.commit())
      })

    Promise.all(promises)
      .then(() => {
        const auditDocRef = firestore.collection('companies').doc(company.id).collection('audits').doc(selectedAudit.id)
        auditDocRef.update({ updatedAt: new Date() })
          .then(() => {
            setLoading(false)
            setShowEditingAudit(false)
          })
      })
      .catch(e => {
        setLoading(false)
        setSubmitDisabled(false)
        window.alert(`Error submitting audit. Check internet connection and try again, ${e}`)
        console.log('error committing audit batch', e)
      })


  }





  function RenderItem({ index, style }) {
    const item = items[index];
    if (!item.active || item.archived) return <View style={{ height: 0 }} />;
    if (isParentItem(parts, item)) {
      return (
        <View style={style}>
          <ManufacturerCard onPress={() => itemSelected(item)} item={item} />
        </View>
      );
    } else {
      return (
        <View style={style}>
          <ModelCard onPress={() => itemSelected(item)} item={item} />
        </View>
      );
    }
  }

  function backPressed() {
    if (selectedItems?.length === 0 || scannedItems?.length === selectedItems.length) {
      navigate(-1)
    } else {
      const confirm = window.confirm('Exit without saving? Any changes made to this audit will be discarded.')
      if (confirm) {
        navigate(-1)
      }
    }
  }

  return (
    <View style={styles.container}>
      <Header
        title={
          "Audit Report - " +
          selectedLocation.name +
          ": " +
          selectedAudit.createdAt.toDate().toDateString()
        }
        onBack={() => backPressed()}
      />
      <View style={{ zIndex: 999, flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>

        {selectedAudit?.complete ? (
          <View></View>
        ) : (
          <TouchableOpacity
            onPress={() => setShowEditingAudit(true)}
            style={{ backgroundColor: Colors.primary.green, padding: 8, borderRadius: 4 }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center', opacity: 1 }}>
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={{ color: Colors.primary.white, marginHorizontal: 4 }}
                numberOfLines={1} >
                {scannedItems?.length > 0 ? "Continue Audit" : "Start Audit"}
              </PrimaryText>
            </View>
          </TouchableOpacity>
        )}

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <TouchableOpacity
            disabled={approveDisabled}
            onPress={() => setShowConfirmModal("approve")}
            style={{ backgroundColor: Colors.primary.lightBlue, padding: 8, borderRadius: 4 }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center', opacity: approveDisabled ? 0.4 : 1 }}>
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={{ color: Colors.primary.white, marginHorizontal: 4 }}
                numberOfLines={1} >
                {"Approve All"}
              </PrimaryText>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => formatAuditReport(
              selectedLocation.name +
              ": " +
              selectedAudit.createdAt.toDate().toDateString(),
              sorted
            )}
            style={{ backgroundColor: Colors.primary.lightBlue, padding: 8, borderRadius: 4, marginLeft: 8 }}
          >
            <FaIcons.FaFileDownload color={Colors.primary.white} size={20} />
          </TouchableOpacity>
        </View>




      </View>

      {filteredItems && filteredItems.length > 0 ? (
        <View style={styles.body}>
          <View style={styles.detailLabelContainer}>
            <PrimaryText
              fontSize={14}
              fontWeight={400}
              style={{ color: Colors.primary.white, width: width * 0.06 }}
            >
              Approved
            </PrimaryText>
            <View style={{ flexDirection: "row" }}>
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={[styles.detailLabel, { width: width * 0.12 }]}
              >
                Original Qty
              </PrimaryText>
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={styles.detailLabel}
              >
                New Qty
              </PrimaryText>
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={styles.detailLabel}
              >
                Adjustment
              </PrimaryText>
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={styles.detailLabel}
              >
                Counted
              </PrimaryText>
            </View>
          </View>

          <FlatList
            style={{
              flex: 1,
              width: "100%",
              borderTopWidth: 1,
              borderColor: Colors.primary.lightGray,
            }}
            data={sorted}
            keyExtractor={(item) => item.id}
            renderItem={({ item, index }) => {
              return (
                <AuditDetailCard
                  item={item}
                  name={
                    item.manufacturer
                      ? getItemName(item)
                      : getItemNameFromManufacturer(item, manufacturers)
                  }
                />
              );
            }}
          />
        </View>
      ) : (
        <View style={styles.noItemsContainer}>
          <PrimaryText fontSize={16} fontWeight={400}>
            {`No items for approval`}
          </PrimaryText>
        </View>
      )}

      {!!showConfirmModal && (
        <ConfirmModal
          title={
            showConfirmModal === "approve"
              ? "Approve All Audit Items"
              : "Mark Audit as Complete?"
          }
          message={"Are you sure?"}
          onConfirm={() =>
            showConfirmModal === "approve"
              ? approveAll()
              : completeAudit(company, selectedAudit, () =>
                setShowConfirmModal(false)
              )
          }
          onCancel={() => setShowConfirmModal(false)}
        />
      )}

      {!!showEditingAudit && (
        <FullModal>
          <ModalHeader
            title={`Audit - ${selectedLocation.name}`}
            onClose={() => setShowEditingAudit(false)}
            leftText={"Submit"}
            onLeftPressed={() => submitTransaction()}
          />
          <View style={styles.modalContent}>
            <View style={styles.topContainer}>
              {/** Buttons */}
              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  onPress={() => setShowBrowseModal("search")}
                  style={styles.itemButtons}
                >
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={styles.itemButtonText}
                  >
                    {"Search Items"}
                  </PrimaryText>
                  <VSIcons.VscSearch
                    name="magnify"
                    color={Colors.primary.white}
                    size={20}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => setShowScanner(true)}
                  style={styles.itemButtons}
                >
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={styles.itemButtonText}
                  >
                    {"Scan Items"}
                  </PrimaryText>
                  <BiBarcode
                    name="magnify"
                    color={Colors.primary.white}
                    size={20}
                  />
                </TouchableOpacity>
              </View>
            </View>

            {selectedItems && selectedItems.length > 0 ? (
              <FlatList
                data={selectedItems}
                keyExtractor={(item, index) => item.id + index}
                renderItem={({ item, index }) => {
                  return (
                    <AuditDetailItemCard
                      onQuantityChanged={(a, b) => itemQtyChanged(a, b)}
                      item={item}
                      name={item.manufacturer ? getItemName(item) : getItemNameFromManufacturer(item, manufacturers)}
                      removeItem={() => removeItem(item)}
                    />
                  );
                }}
              />
            ) : (
              <View style={styles.emptyContainer}>
                <PrimaryText fontSize={16} fontWeight={400}>
                  No Items Selected
                </PrimaryText>
              </View>
            )}
          </View>
        </FullModal>
      )}

      {/** BROWSE MODEL */}
      {!!showBrowseModal && (
        <FullModal>
          <View style={styles.modalContent}>
            <View style={styles.modalHeader}>
              {selectedParent ? (
                <Pressable
                  underlayColor={"transparent"}
                  style={{ width: 40 }}
                  onPress={() => setSelectedParent(null)}
                >
                  <FaIcons.FaChevronLeft
                    name="chevron-left"
                    color={Colors.primary.darkGray}
                    size={24}
                  />
                </Pressable>
              ) : (
                <PrimaryText
                  fontSize={20}
                  fontWeight={400}
                  style={{ width: 40 }}
                ></PrimaryText>
              )}

              <PrimaryText fontSize={18} fontWeight={400}>
                {selectedParent
                  ? parts.find((x) => x.id === selectedParent.id).model
                  : "Items"}
              </PrimaryText>

              <TouchableOpacity onPress={() => setShowBrowseModal(false)}>
                <AiIcons.AiOutlineClose
                  color={Colors.primary.darkGray}
                  size={24}
                  style={{ width: 40 }}
                />
              </TouchableOpacity>
            </View>

            <CustomInput
              placeholder={"Search Items"}
              style={{ width: "90%", marginBottom: 4, paddingVertical: 4 }}
              onChange={setSearch}
              value={search}
            />

            <ListContainer
              items={items}
              size={40}
              renderItem={RenderItem}
              listWidth={400}
            />
          </View>
        </FullModal>
      )}

      <Scanner
        // editing={true}
        showScanner={showScanner}
        setShowScanner={setShowScanner}
        scannedItem={scannedItem}
        setScannedItem={setScannedItem}
        title={"Scan To Search"}
        setSelectedItem={itemSelected}
      // setUpc={setUpc}
      />

      {
        loading && (
          <ActivityIndicator
            animating={loading}
            size={"large"}
            style={StyleSheet.absoluteFill}
            color={Colors.primary.blue}
          />
        )
      }
    </View >
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  noItemsContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  body: {
    flex: 1,
    width: "100%",
    backgroundColor: Colors.primary.white,
    marginTop: 12,
    alignItems: "center",
  },
  optionContainer: {
    paddingHorizontal: 12,
    // width: 300,
    flexDirection: "row",
    alignItems: "center",
    // justifyContent:'space-between',
    marginVertical: 12,
  },

  //details
  detailLabelContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 12,
    marginTop: 12,
    backgroundColor: Colors.primary.gray,
  },
  detailLabel: {
    width: width * 0.09,
    textAlign: "center",
    color: Colors.primary.white,
  },

  //modal
  modal: {
    // justifyContent: 'flex-end',
    margin: 0,
  },
  modalContent: {
    height: height * 0.6,
    minWidth: 500,
    backgroundColor: Colors.primary.white,
    borderRadius: 12,
    padding: 12,
    // alignItems: 'center'
  },
  modal: {
    // justifyContent: 'flex-end',
    margin: 0,
    marginBottom: height * 0.3,
  },
  modalHeader: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },

  //modal
  topContainer: {
    marginBottom: 12,
  },
  locationContainer: {
    flexDirection: "row",
    paddingVertical: 12,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  locationButton: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "70%",
    marginRight: "10%",
    borderBottomWidth: 1,
    borderBottomColor: Colors.primary.lightGray,
    paddingVertical: 4,
  },
  buttonContainer: {
    flexDirection: "row",
    paddingVertical: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  itemButtons: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.primary.red,
    borderRadius: 4,
    paddingVertical: 6,
    marginHorizontal: 4,
    width: "30%",
  },
  itemButtonText: {
    color: Colors.primary.white,
    marginRight: 6,
  },
});
