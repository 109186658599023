import React, { useState, createContext, useEffect, useContext } from "react";
import { fetchDToolsProject } from "services/dtools-service";
import { getCustomers } from "~/services/customers-service";
import { useCompany } from "./CompanyContext";
import { usePersistState } from "./usePersistState";

export const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {

    const {company, isDTools} = useCompany()

    const [customers, setCustomers] = usePersistState('CUSTOMERS_customers', null)
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [dToolsProjectItems, setDToolsProjectItems] = useState(null)
    const [dToolsProjectLocations, setDToolsProjectLocations] = useState(null)
 
    useEffect(() => {
        let subscriber
        if (company) {
            subscriber = getCustomers(company)
                .onSnapshot(documentSnapshot => {
                const temp = []
                documentSnapshot && documentSnapshot.forEach(doc => {
                    const data = doc.data()
                    
                    const customer = {
                        id: doc.id,
                        ...data,
                        lowercaseFullName: data.lowercaseFullName.replaceAll("'", '‘').replaceAll('"', '“') //for searching
                    }
                    
                    if (customer.serviceOrder) customer.fullName = ('SO: '+customer.fullName)
                    temp.push(customer)
                })
                const sorted = temp.sort((a, b) => a.lowercaseFullName.toLowerCase() > b.lowercaseFullName.toLowerCase() ? 1 : -1)
                setCustomers(sorted)
            })

            return () => subscriber && subscriber()

        } else {
            subscriber && subscriber()
        }
        
    }, [company])

    useEffect(() => {
        if (!customers || !isDTools) return

        customers.forEach(customer => {
            if (customer.parent_id) {
                const find = customers.find(x => x.id===customer.parent_id)
                if (find) {
                    const fullName = find.name+': '+customer.name
                    customer.fullName = fullName
                    customer.lowercaseFullName = fullName.toLowerCase()
                }
            }
        })

        
    }, [customers])

    useEffect(() => {
        if (!selectedCustomer || !isDTools) return
        getDToolsProjectItems()
    }, [selectedCustomer])

    function getDToolsProjectItems() {
        fetchDToolsProject(company, selectedCustomer.id, setDToolsProjectItems, setDToolsProjectLocations)
    }

    return (
        <CustomerContext.Provider value={{ 
            customers, setCustomers,
            selectedCustomer, setSelectedCustomer,
            dToolsProjectItems, dToolsProjectLocations,
            getDToolsProjectItems
        }}>
        {children}
        </CustomerContext.Provider>
    );
};

export const useCustomers = () => useContext(CustomerContext)
