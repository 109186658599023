import React, { useEffect, useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { PrimaryText } from "components/Common/PrimaryText";
import { FullModal } from "components/Modals/FullModal";
import * as AiIcons from "react-icons/ai";

import { Colors } from "~/constants/Colors";
import { Waveform } from "@uiball/loaders";
import { TextInput } from "react-native-web";

let tempScannedQr = "";
let timer;

export function POScanner(props) {
  const {
    showScanner,
    setShowScanner,
    title,
    keepScannerOpen = false,
    selectedStagedItem = null,
    stagedParts = [],
    setSelectedItems,
  } = props;
  let { selectedItems = [] } = props;

  const [qrCode, setQrCode] = useState(null);
  const [scannedItem, setScannedItem] = useState(null);
  const [removed, setRemoved] = useState(false);

  //setup keyboard listener
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [showScanner]);

  function handleKeyDown(e) {
    if (e.key === "Escape") {
      !keepScannerOpen && setShowScanner(false);
    }
    if (showScanner && e.key?.length === 1) {
      tempScannedQr = tempScannedQr + e.key;
      timer = setTimeout(() => {
        setQrCode(tempScannedQr);
        qrScanned(tempScannedQr);
      }, 250);
    }
  }

  function qrScanned(qrCode) {
    if (!qrCode) {
      return;
    }
    const docId = qrCode

    // console.log("docId", docId);
    const item = stagedParts.find((x) => x?.id === docId);
    purchaseOrderItemFound(item);
    return;
  }

  useEffect(() => {
    if (!showScanner) {
      setQrCode(null);
      setScannedItem(null);
      tempScannedQr = "";
    }
  }, [showScanner]);

  function purchaseOrderItemFound(item) {
    // console.log("item", item);
    if (!item) return;
    setScannedItem(item);
    selectedStagedItem(item);

    const cloned = [...selectedItems];
    const find = cloned.find((x) => x?.id === item?.id);
    if (find) {
      //remove
      selectedItems = cloned.filter((x) => x.id !== item.id);
      setSelectedItems(cloned.filter((x) => x.id !== item.id));
      setRemoved(true);
    } else {
      cloned.push(item);
      selectedItems = cloned;
      setSelectedItems(cloned);
      setRemoved(false);
    }


    setQrCode(null);
    tempScannedQr = "";
  }

  return (
    showScanner && (
      <FullModal>
        <View style={[styles.modal, { minHeight: undefined }]}>
          <View style={styles.modalHeader}>
            <PrimaryText
              fontSize={20}
              fontWeight={400}
              style={{ width: 40 }}
            ></PrimaryText>
            <PrimaryText fontSize={20} fontWeight={400}>
              {title}
            </PrimaryText>
            <TouchableOpacity onPress={() => setShowScanner(false)}>
              <AiIcons.AiOutlineClose
                name="barcode"
                color={Colors.primary.darkGray}
                size={24}
                style={{ width: 40 }}
              />
            </TouchableOpacity>
          </View>
          {qrCode || scannedItem ? (
            <>
              <PrimaryText fontSize={14} fontWeight={400}>
                {scannedItem
                  ? `${scannedItem.itemFullName} ${
                      removed ? "Removed " : "Added"
                    }`
                  : "No Item Found"}
              </PrimaryText>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <TextInput
                  //   autoFocus={true}
                  disabled={true}
                  style={{
                    height: 40,
                    borderColor: "gray",
                    borderWidth: 1,
                    width: "100%",
                    // marginTop: 20,
                    padding: 10,
                    marginRight: 12,
                  }}
                  onChangeText={(text) => {
                    setQrCode(text);
                    qrScanned(text);
                  }}
                  value={
                    scannedItem ? scannedItem?.id : qrCode
                  }
                />
                <TouchableOpacity
                  onPress={() => {
                    setQrCode("");
                    setScannedItem(null);
                    tempScannedQr = "";
                  }}
                >
                  <AiIcons.AiOutlineCloseSquare
                    color={Colors.primary.darkGray}
                    size={24}
                  />
                </TouchableOpacity>
              </View>
            </>
          ) : (
            <View style={{ alignItems: "center" }}>
              <Waveform size={40} lineWeight={3.5} speed={2} color="black" />
            </View>
          )}
        </View>
      </FullModal>
    )
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    backgroundColor: Colors.primary.white,
  },
  body: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
    backgroundColor: Colors.primary.white,
  },
  modal: {
    minWidth: 400,
    minHeight: 200,
    maxHeight: 600,
  },
  modalHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
    borderColor: Colors.primary.gray,
    borderBottomWidth: 0.5,
    paddingBottom: 12,
  },
});
