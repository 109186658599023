import React, { useEffect, useState } from "react";
import { StyleSheet, View, FlatList, TouchableOpacity } from "react-native";
import { Colors } from "~/constants/Colors";
import { Header } from "components/Header";
import { useCompany } from "context/CompanyContext";
import { AuditCard } from "components/Cards/AuditCard";
import { completeAudit, deleteAudit, getAuditByLocation } from "~/services/audits-service";
import { useNavigate } from "react-router-dom";
import { Links } from "constants/Links";
import { useAuth } from "context/AuthContext";
import { PrimaryText } from "components/Common/PrimaryText";
import * as FaIcons from "react-icons/fa";
import { createAudit } from "services/audits-service";

export function AuditsList() {

  const navigate = useNavigate()

  const { currentUser } = useAuth()
  const { company, setSelectedAudit, selectedLocation } = useCompany()

  const [audits, setAudits] = useState([])
  const [pending, setPending] = useState(false)

  useEffect(() => {
    const subscriber = getAuditByLocation(company, selectedLocation, setAudits, setPending)
    return () => subscriber()
  }, [selectedLocation])


  function auditSelected(audit) {
    setSelectedAudit(audit)
    navigate(Links.AuditReport)
  }

  return (
    <View style={styles.listContainer}>
      <Header
        title={selectedLocation?.name + ' Audits'}
        // onBack={selectedParent ? () => setSelectedParent(null) : null} 
        onRight={currentUser?.admin ? () => console.log('yay') : null}
        rightText={'Start New Audit'}
        rightButton={currentUser?.admin ? <TouchableOpacity
          onPress={() => {
            pending ? 
            window.alert('Pending Audits. You can only have 1 pending audit per location. Please close out any pending audits in the list below') 
            : 
            createAudit(company, selectedLocation, currentUser, auditSelected)
          }}
          style={{ backgroundColor: Colors.primary.lightBlue, padding: 8, borderRadius: 4, marginLeft: -110 }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center', opacity: 1 }}>
            <PrimaryText fontSize={16} fontWeight={400} style={{ color: Colors.primary.white }}>Start New Audit</PrimaryText>

            <FaIcons.FaClipboardList name="pencil" color={Colors.primary.white} size={12} style={{ marginLeft: 8, marginBottom: 2 }} />

          </View>
        </TouchableOpacity>
          : null}
      />
      <FlatList
        style={{ flex: 1 }}
        contentContainerStyle={{ backgroundColor: Colors.primary.white }}
        data={audits}
        keyExtractor={item => item.id}
        renderItem={({ item, index }) => {
          return (
            <AuditCard item={item} onPress={() => auditSelected(item)} />
          )
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  listContainer: {
    // flex: 1,
    width: '60%',
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  }
});
