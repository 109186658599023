import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import * as AiIcons from 'react-icons/ai';

import { Colors } from "~/constants/Colors";
import { PrimaryText } from "~/components/Common/PrimaryText";

export function ModalHeader({ title, onClose, leftText = "", onLeftPressed = null }) {

  return (

    <View style={styles.modalHeader}>
      {leftText && (
        <TouchableOpacity onPress={onLeftPressed} style={{ position: 'absolute', left: 0 }}>
          <PrimaryText fontSize={16} fontWeight={400} style={{color: Colors.primary.red}}>{leftText}</PrimaryText>
        </TouchableOpacity>
      )}
      <PrimaryText fontSize={18} fontWeight={400} style={{ alignSelf: 'center' }}>
        {title}
      </PrimaryText>
      <TouchableOpacity onPress={onClose} style={{ position: 'absolute', right: 0 }}>
        <AiIcons.AiOutlineClose color={Colors.primary.darkGray} size={24} style={{ width: 40 }} />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
    backgroundColor: Colors.primary.white,
    // flexDirection: 'row',
    // justifyContent: 'space-between',
    marginBottom: 20,
    borderColor: Colors.primary.gray,
    borderBottomWidth: 0.5,
    paddingBottom: 12
  }

});
