import { firestore } from "utils/firebase";
import firebase from "firebase/app";
import { Alert } from "react-native";

export const getAuditByLocation = (company, location, setAudits, setPending) => {
  // console.log(companyId, userId)
  return firestore
    .collection("companies")
    .doc(company.id)
    .collection("audits")
    .where("location", "==", location.id)
    .orderBy("createdAt", "desc")
    .onSnapshot((querySnapshot) => {
      const temp = [];
      let pending = false;
      querySnapshot.forEach((doc) => {
        const audit = {
          id: doc.id,
          ...doc.data(),
        };
        if (!audit.complete) pending = true;
        temp.push(audit);
      });
      setAudits(temp);
      setPending(pending);
    });
};

export const deleteAudit = (company, audit) => {
  Alert.alert("Delete Audit?", "Are you sure? This action cannot be undone", [
    {
      text: "No",
      onPress: () => console.log("Cancel Pressed"),
      style: "cancel",
    },
    {
      text: "Yes",
      onPress: () => {
        firestore
          .collection("companies")
          .doc(company.id)
          .collection("audits")
          .doc(audit.id)
          .delete();
      },
      style: "destructive",
    },
  ]);
};

export const createAudit = (company, location, currentUser, onSuccess) => {
  const audit = {
    createdAt: new Date(),
    location: location.id,
    updatedAt: new Date(),
    user: currentUser.id
  }
  firestore
    .collection('companies')
    .doc(company.id)
    .collection('audits')
    .add(audit)
    .then((docRef) => {
      audit.id = docRef.id
      audit.createdAt = firebase.firestore.Timestamp.fromDate(audit.createdAt)
      audit.updatedAt = firebase.firestore.Timestamp.fromDate(audit.updatedAt)
      onSuccess(audit)
    })
}

export const completeAudit = (company, audit, onSuccess) => {
  firestore
    .collection("companies")
    .doc(company.id)
    .collection("audits")
    .doc(audit.id)
    .update({ complete: true })
    .then(() => {
      onSuccess();
    });
};

export const getAuditItems = (company, audit, setItems) => {
  return firestore
    .collection("companies")
    .doc(company.id)
    .collection("audits")
    .doc(audit.id)
    .collection("scanned")
    .orderBy("scanTime", "desc")
    .onSnapshot((querySnapshot) => {
      const temp = [];
      querySnapshot.forEach((doc) => {
        const audit = {
          id: doc.id,
          ...doc.data(),
        };
        temp.push(audit);
      });
      setItems(temp);
    });
};

export const approveAuditItems = (
  company,
  currentUser,
  audit,
  locationParts,
  location,
  items,
  onSuccess
) => {
  let perChunk = 50;
  let chunks = items.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  let promises = [];

  chunks &&
    chunks?.length > 0 &&
    chunks.forEach((chunk) => {
      const batch = firestore.batch();
      chunk.forEach((item) => {
        const adjustment = item.newQty - item.locationQty;

        const scannedItemRef = firestore
          .collection("companies")
          .doc(company.id)
          .collection("audits")
          .doc(audit.id)
          .collection("scanned")
          .doc(item.id);
        const scannedItemDataToUpdate = { approved: true };
        batch.update(scannedItemRef, scannedItemDataToUpdate);

        const partRef = firestore
          .collection("companies")
          .doc(company.id)
          .collection("parts")
          .doc(item.id);
        const partDataToUpdate = {
          qty: firebase.firestore.FieldValue.increment(adjustment),
        };
        partDataToUpdate.lastModified = new Date();
        batch.update(partRef, partDataToUpdate);

        const locationPartId = `${location.id}-${item.id}`;
        const exists = locationParts.find(
          (x) => x.locationPartId === locationPartId
        );
        const locationPartRef = firestore
          .collection("companies")
          .doc(company.id)
          .collection("locationParts")
          .doc(locationPartId);

        if (exists) {
          batch.update(locationPartRef, { qty: item.newQty });
        } else {
          const locationPart = {
            locationID: location.id,
            locationName: location.name,
            lowercaseMfg: item.lowercaseMfg,
            lowercaseModel: item.lowercaseModel,
            partID: item.id,
            qty: item.newQty,
          };
          batch.set(locationPartRef, locationPart);
        }

        const history = {
          manufacturer: item?.manufacturer,
          model: item?.model,
          from: adjustment < 0 ? location.name : "Audit Adjustment",
          fromLocationId: adjustment < 0 ? location.id : "audit",
          fromClientId: "",
          to: adjustment < 0 ? "Audit Adjustment" : location.name,
          toLocationId: adjustment < 0 ? "audit" : location.id,
          toClientId: "",
          date: new Date(),
          qty: Math.abs(adjustment),
          user: currentUser.id,
          received: false,
          approved: true,
          partID: item.id,
          custom: false,
          rejected: false,
          manualAdjustment: true,
        };
        const historyRef = firestore
          .collection("companies")
          .doc(company.id)
          .collection("history")
          .doc();
        batch.set(historyRef, history);
      });
      promises.push(batch.commit());
    });

  Promise.all(promises)
    .then(() => {
      onSuccess();
    })
    .catch((e) => {
      console.log("error saving audited items", e);
      //   Alert.alert('Error updating item', 'Check internet connection and try again')
    });
};
