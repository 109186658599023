import React, { useState, createContext, useEffect, useContext, useMemo } from "react";
import { listenToParts, updatePart } from "services/parts-service";
import { getCompanyStagedItems } from "services/purchaseOrders-service";
import { firestore } from "utils/firebase";
import { getItemName, getItemNameFromManufacturer } from "utils/helpers";
import {
  getHistory,
  getLocationParts,
  getParts,
  getServiceItems,
  getSpecificStagedItems,
} from "~/services/parts-service";
import { useAuth } from "./AuthContext";
import { useCompany } from "./CompanyContext";
import { usePersistState } from "./usePersistState";

export const PartsContext = createContext();
let timer;
let alreadyLooping = false;
export const PartsProvider = ({ children }) => {
  const {
    company,
    setLoading,
    setCompany,
    setIsDTools,
    setUsers,
    setLocations,
    setSelectableLocations,
    stagingAreaSubLocations,
  } = useCompany();
  const { currentUser, currentAuth } = useAuth();

  const [parts, setParts, partsFetched] = usePersistState("PARTS_parts", []);
  const [startListenToParts, setStartListenToParts] = useState(false);
  const [locationParts, setLocationParts] = usePersistState("PARTS_locationParts", []);
  const [startListenToLocationParts, setStartListenToLocationParts] = useState(false);


  const [serviceItems, setServiceItems] = usePersistState(
    "PARTS_serviceItems",
    null
  );
  const [manufacturers, setManufacturers] = usePersistState(
    "PARTS_manufacturers",
    null
  );
  const [models, setModels] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemHistory, setItemHistory] = useState(null);
  const [itemByLocation, setItemByLocation] = useState(null);

  const [stagedItems, setStagedItems] = useState(null);
  const [stagedParts, setStagedParts] = useState([]);
  const [getStagedItems, setGetStagedItems] = useState(false);

  const [
    initialSync,
    setInitialSync,
    initialSyncFetched,
    setInitialSyncFetched,
  ] = usePersistState("PARTS_initialSync", null);
  const [syncingInitialData, setSyncingInitialData] = useState(false);

  function clearState() {
    console.log("clearing state")
    setCompany(null);
    setIsDTools(false);
    setUsers(null);
    setLocations(null);
    setSelectableLocations(null);
    setInitialSync(null);
    setInitialSyncFetched(false);
    setSyncingInitialData(false);
    setParts(null);
    setStartListenToParts(false);
    setStartListenToLocationParts(false);
    setLocationParts(null);
    setServiceItems(null);
    setManufacturers(null);
    window.location.reload();
  }

  useEffect(() => {
    // console.log('here2', currentAuth?.uid)
    if (initialSyncFetched && !initialSync) {
      //   console.log('should start?')
      timer = setTimeout(() => {
        // console.log('should set?')
        setSyncingInitialData(true);
      }, 500);
    } else if (initialSyncFetched && initialSync) {
      //   console.log('should set false?')
      setSyncingInitialData(false);
    }
    return () => {
      //   console.log('should clear?')
      timer && clearTimeout(timer);
    };
  }, [initialSync, initialSyncFetched]);

  useEffect(() => {
    if (company) {
      //    setStartGetParts(true)
      setStartListenToParts(true);
    }
  }, [company?.id]);

  let partSub
  useEffect(() => {
    if (!startListenToParts || !partsFetched || !initialSyncFetched) return;

    // console.log('start listen to parts', !startListenToParts, !partsFetched, !initialSyncFetched)
    partSub = listenToParts(
      company,
      initialSync,
      setInitialSync,
      parts,
      gotParts,
      setManufacturers,
      setLoading
    );

    return () => partSub && partSub();
  }, [startListenToParts, partsFetched, initialSyncFetched]);

  function gotParts(parts) {
    setParts(parts);
    setStartListenToLocationParts(true);
    // console.log('setting parts', parts?.length)
  }

  /** LOCATION PARTS */
  //find where these are used and do them all locally?
  // down from 3 seconds to 2.5 milliseconds
  useEffect(() => {


    let subscriber;
    if (startListenToLocationParts && company) {
      const temp = [...locationParts];
      subscriber = getLocationParts(company).onSnapshot((documentSnapshot) => {
        console.time('locationParts')

        documentSnapshot.docChanges().forEach((change) => {
          if (change.type === "added" || change.type === "modified") {

            const doc = change.doc;
            const data = doc.data();
            const realPart = parts.find((x) => x.id === data.partID);
            if (realPart) {
              let part = {
                id: doc.id,
                ...realPart,
              };

              const parent = parts.find((x) => x.id === part.parentRefID);
              if (parent) {
                part.manufacturer = parent.model;
                part.lowercaseMfg = parent.lowercaseModel;
              }

              part.locationQty = data.qty;
              part.minStockQty = data.minStockQty ? data.minStockQty : 0;
              part.maxStockQty = data.maxStockQty ? data.maxStockQty : 0;
              part.subLocation = data.subLocation
                ? data.subLocation
                : undefined;
              part.locationID = data.locationID;
              part.partID = data.partID;
              part.locationPartId = `${data.locationID}-${data.partID}`;

              //check if exists
              const exists = temp.findIndex(x => x.locationPartId === part.locationPartId)
              if (exists !== -1) {
                temp[exists] = part
              } else {
                temp.push(part);
              }
            }
          }
          if (change.type === "removed") {
            console.log("Removed location part: ", change.doc.id);
            const index = temp.findIndex(
              (x) => x.locationPartId === change.doc.id
            );
            if (index !== -1) {
              temp.splice(index, 1);
            }
          }
        });

        const sorted = temp.sort((a, b) =>
          a.lowercaseMfg > b.lowercaseMfg && a.lowercaseModel > b.lowercaseModel
            ? 1
            : -1
        );
        setLocationParts(sorted);
        console.timeEnd('locationParts')

      });

      getServiceItems(company, setServiceItems);
    }

    return () => subscriber && subscriber();
  }, [startListenToLocationParts]);



  /** START STAGING */
  // from .5 seconds to 0.75 milliseconds
  useEffect(() => {
    let subscriber;
    if (!!getStagedItems && currentUser) {
      const temp = [...stagedParts];
      console.log('start staged parts length', temp.length)
      subscriber = getCompanyStagedItems(company).onSnapshot(
        (querySnapshot) => {
          console.time('stagedParts')

          querySnapshot.docChanges().forEach((change) => {
            if (change.type === "added" || change.type === "modified") {
              const doc = change.doc;
              const data = doc.data();
              if (data.deleted) return;
              const item = {
                ...data,
                id: doc.id,
              };
              if (!item.customerId) return; //stock - probably will never trigger - being handled by cloud function

              const part = parts.find((x) => x.id === item.itemListId);
              if (part)
                item.itemFullName = part.manufacturer
                  ? getItemName(part)
                  : getItemNameFromManufacturer(part, manufacturers);
              else if (!item.itemFullName) item.itemFullName = "deleted item";

              item.cost = part ? part.cost : 0;

              //only for staging report, not total valuation check
              const customer = getStagedItems?.length && getStagedItems.find((x) => x.id === item.customerId);
              item.customerFullName =
                (customer && customer.fullName)
                  ? customer.fullName
                  : `deleted customer ${item?.customerId}`;
              if (
                customer &&
                customer?.stagingArea &&
                stagingAreaSubLocations.find(
                  (x) => x.id === customer?.stagingArea
                )
              ) {
                item.stagingArea = customer?.stagingArea;
              } else {
                item.stagingArea = null;
              }

              if (!item.rejected) {
                //check if exists
                const exists = temp.findIndex(x => x.id === item.id)
                if (exists !== -1) {
                  temp[exists] = item
                } else {
                  temp.push(item);
                }
              }
            }
            if (change.type === "removed") {
              console.log("Removed staged part: ", change.doc.id);
              const index = temp.findIndex((x) => x.id === change.doc.id);
              if (index !== -1) {
                temp.splice(index, 1);
              }
            }
          });

          try {
            console.log('end staged parts length', temp.length)
            const sorted = temp.sort((a, b) =>
              a.customerFullName.toLowerCase() > b.customerFullName.toLowerCase()
                ? 1
                : -1
            );

            setStagedParts(sorted);
          } catch (e) {
            console.log('error sorting staged parts', e)
          }
          console.timeEnd('stagedParts')

        },
        (error) => {
          console.log("error getting staged items", error);
        }
      );
      return () => subscriber && subscriber();
    } else {
      setGetStagedItems(false);
      subscriber && subscriber();
    }
  }, [getStagedItems]);

  const contextValue = useMemo(() => ({
    parts,
    setParts,
    locationParts,
    setLocationParts,
    serviceItems,
    setServiceItems,
    manufacturers,
    setManufacturers,
    models,
    setModels,
    selectedItem,
    setSelectedItem,
    stagedItems,
    setStagedItems,
    stagedParts,
    setStagedParts,
    getStagedItems,
    setGetStagedItems,
    initialSync,
    setInitialSync,
    initialSyncFetched,
    setInitialSyncFetched,
    syncingInitialData,
    clearState,
  }), [parts,
    setParts,
    locationParts,
    setLocationParts,
    serviceItems,
    setServiceItems,
    manufacturers,
    setManufacturers,
    models,
    setModels,
    selectedItem,
    setSelectedItem,
    itemHistory,
    setItemHistory,
    itemByLocation,
    setItemByLocation,
    stagedItems,
    setStagedItems,
    stagedParts,
    setStagedParts,
    getStagedItems,
    setGetStagedItems,
    initialSync,
    setInitialSync,
    initialSyncFetched,
    setInitialSyncFetched,
    syncingInitialData,
    clearState,
  ])

  return (
    <PartsContext.Provider
      value={contextValue}
    >
      {children}
    </PartsContext.Provider>
  );
};

export const useParts = () => useContext(PartsContext);
