import React from "react";
import { useAuth } from '../context/AuthContext';
import { Landing } from "~/screens/Auth/Landing";
import { PartsProvider } from "~/context/PartsContext";
import { CustomerProvider } from "~/context/CustomersContext";
import { NotificationsProvider } from "~/context/NotificationsContext";
import Sidebar from "components/Sidebar/Sidebar";
import { BrowserRouter as Router, Navigate, HashRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import MainNavigator from "./MainNavigator";
import { Links } from "constants/Links";

export default function RootNavigator() {
  const authContext = useAuth()
  const {
    currentUser
  } = authContext

  return (

    currentUser === null ? (
      <HashRouter>
        <Routes>
          <Route path={Links.Landing} element={<Landing />} />
          <Route path={'/*'} element={<Navigate replace to={Links.Landing} />} />
        </Routes>
      </HashRouter>

    ) : (

      <CustomerProvider>
          <NotificationsProvider>
            <PartsProvider>
              <HashRouter>
                <Sidebar />
                <MainNavigator />
              </HashRouter>
            </PartsProvider>
          </NotificationsProvider>
      </CustomerProvider>

    )


  );
}
