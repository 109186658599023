import cloneDeep from "lodash/cloneDeep";
import { Alert } from "react-native";

/** ITEMS */
export const searchParts = (search, selectedParent, items, setItems, parts, manufacturers, showBrowseModal, filterActive) => {

    if (search) {
        if (selectedParent) {
            let temp = items.filter(x => x.lowercaseModel.includes(search.toLowerCase())
            || (x.partNumber && x.partNumber.toLowerCase().includes(search.toLowerCase()))
            || (x.upc && x.upc.toLowerCase().includes(search.toLowerCase()))
            || (x.description && x.description.toLowerCase().includes(search.toLowerCase()))
        )
            if (filterActive) {
                temp = temp.filter(x => x.active && !x.archived)
            }
            setItems(temp)
        } else {
            if (showBrowseModal === 'search') {
                // const temp = parts.filter(x => x.lowercaseModel.includes(search.toLowerCase()))
                let temp = parts.filter(
                    x => x.active
                        && !x.archived
                        && (
                            x.lowercaseModel.includes(search.toLowerCase())
                            || (x.partNumber && x.partNumber.toLowerCase().includes(search.toLowerCase()))
                            || (x.upc && x.upc.toLowerCase().includes(search.toLowerCase()))
                            || (x.description && x.description.toLowerCase().includes(search.toLowerCase()))
                        )
                )

                if (filterActive) {
                    temp = temp.filter(x => x.active && !x.archived)
                }
                setItems(temp)
            } else {
                let temp = parts.filter(x => x.lowercaseModel.includes(search.toLowerCase()) && (!x.parentRefID && !x.manufacturer))
                if (filterActive) {
                    temp = temp.filter(x => x.active && !x.archived)
                }
                setItems(temp)
            }
        }

    } else {
        if (selectedParent) {
            const temp = parts.filter(x => x.parentRefID === selectedParent.id)
            setItems(temp)
        } else {
            setItems(manufacturers)
        }
    }

}

export const selectPart = (item, parts, setSelectedParent, setItems, setSelectedItems, setSearch, setShowBrowseModal, isArray, selectedItems) => {
    const clonedItem = cloneDeep(item)
    if (isParentItem(parts, clonedItem)) {
        clonedItem.timeStamp = new Date().getTime().toString()

        const temp = parts.filter(x => x.parentRefID === clonedItem.id)
        setSelectedParent(temp ? clonedItem : null)
        setItems(temp ? temp : clonedItem)
    } else {
        if (isArray) {
            clonedItem.qty = 1
            clonedItem.timeStamp = new Date().getTime().toString()
            const temp = cloneDeep(selectedItems)
            temp.push(clonedItem)
            setSelectedItems(temp)
        } else {
            clonedItem.timeStamp = new Date().getTime().toString()
            setSelectedItems(clonedItem)
        }

        setSearch(null)
        setSelectedParent(null)
        setShowBrowseModal(false)
    }
}

export const selectPartForAudit = (item, selectedLocation, parts, locationParts, setSelectedParent, setItems, selectedItems, setSelectedItems, setSearch, setShowBrowseModal, setShowBarcodeModal, setSelectedItem) => {
    const clonedItem = cloneDeep(item)
    if (isParentItem(parts, clonedItem)) {
        clonedItem.timeStamp = new Date().getTime().toString()
        if (!parts?.length) return;
        const temp = parts.filter(x => x.parentRefID===clonedItem.id)
        setSelectedParent(temp ? clonedItem : null)
        setItems(temp ? temp : clonedItem)
    } else {
        const exists = selectedItems.find(x => x.id===item.id)
        if (exists) {
            setTimeout(() => {
                window.alert('Item already added. Please find the item in the list and adjust the appropriate total quantity')
            }, 200);
            
        } else {
            const locationPart = locationParts.find(x => x.partID===item.id && x.locationID===selectedLocation.id)
            clonedItem.originalQty = locationPart ? locationPart.locationQty : 0
            clonedItem.newQty = locationPart ? locationPart.locationQty : 0
            clonedItem.timeStamp = new Date().getTime().toString()
            const temp = cloneDeep(selectedItems)
            temp.push(clonedItem)
            console.log('cloned item', clonedItem)
            setSelectedItems(temp)

            if (!item.upc) {
                setTimeout(() => {
                    Alert.alert('No UPC Learned', 'Would you like to learn the UPC now?',
                    [
                        {
                          text: "No",
                          onPress: () => console.log("Cancel Pressed"),
                          style: "cancel"
                        },
                        { 
                          text: "Yes", 
                          onPress: () => {
                              setSelectedItem(item)
                              setShowBarcodeModal('learn')
                          },
                          style: 'default' 
                        }
                    ]
                    )
                }, 200);
            }
        }

        setSearch(null)
        setSelectedParent(null)
        setShowBrowseModal(false)
        
    }
}

export const isParentItem = (parts, item) => {
    return (!!parts.find(x => x.parentRefID === item.id))
}

/** CUSTOMERS */
export const searchCustomers = (customerSearch, customers, setFilteredCustomers) => {
    if (customerSearch) {
        const temp = customers.filter(x => x.lowercaseFullName.includes(customerSearch.toLowerCase()))
        setFilteredCustomers(temp)
    } else {
        setFilteredCustomers(customers)
    }
}

export const selectCustomer = (customers, item, setClient, setCustomerSearch, setShowCustomerModal, setFilteredCustomers) => {
    if (!isParentCustomer(customers, item)) {
        setClient(item)
        setCustomerSearch(null)
        setShowCustomerModal(false)
    } else {
        const temp = customers.filter(x => x.parent_id === item.id)
        setFilteredCustomers(temp)
    }
}

export const isParentCustomer = (customers, item) => {
    return (!!customers.find(x => (x.parent_id === item.id) && x.active))
}

/** LOCATION SELECTION */
export const selectLocation = (location, showLocationModal, setFrom, setTo, setShowLocationModal, setShowCustomerModal) => {
    const isClientSelected = location.id === 'client' //check if we're clicking the main Client selector
    if (isClientSelected) {
        setShowCustomerModal(true)
    } else {
        if (showLocationModal === 'from') {
            setFrom({ id: location.id, name: location.name, isCustomer: false })
        } else {
            setTo({ id: location.id, name: location.name, isCustomer: false })
        }
        setShowLocationModal(null)
    }
}

export const getLocationOption = (locations, location) => {
    const found = locations.find(x => x.id === location)
    return {
        value: found.id,
        label: found.name
    }
}

export const selectCustomerLocation = (
    customers,
    customer,
    showLocationModal, setShowLocationModal,
    setFrom, setTo,
    setShowCustomerModal,
    setCustomerSearch,
    setFilteredCustomers,
) => {

    //if it's not a parent, select this item
    if (!isParentCustomer(customers, customer)) {

        if (showLocationModal === 'from') {
            setFrom({ id: customer.id, name: customer.fullName, isCustomer: true, stagingArea: customer?.stagingArea })
        } else {
            setTo({ id: customer.id, name: customer.fullName, isCustomer: true, stagingArea: customer?.stagingArea })
        }
        setCustomerSearch(null)
        setShowLocationModal(false)
        setShowCustomerModal(false)

    } else {
        // if this customer is a parent, get the children
        const temp = customers.filter(x => x.parent_id === customer.id)
        setFilteredCustomers(temp)
    }
}


/** HELPERS */
export function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function getMaxUserCountForSubscription(userCount) {
    if (userCount > 20) {
        return 999
    } else if (userCount < 11) {
        if (userCount > 2) {
            return 10
        } else {
            return 2
        }
    } else {
        return 20
    }
}

export function getNewPriceForSubscription(userCount) {
    if (userCount > 20) {
        return 60 + (userCount * 7)
    } else if (userCount < 11) {
        if (userCount > 2) {
            return 125
        } else {
            return 50
        }
    } else {
        return 200
    }
}

export function getItemName(item) {
    return item.manufacturer ? item.manufacturer + ': ' + item.model : item.model
}

export function getItemNameFromManufacturer(item, manufacturers) {
    if (item.parentRefID) {
        const parent = manufacturers && manufacturers.find(x => x.id === item.parentRefID)
        item.manufacturer = parent ? parent.model : ''
        item.lowercaseMfg = parent ? parent.model.toLowerCase() : ''
    }
    return item.manufacturer ? item.manufacturer + ': ' + item.model : item.model
}

export function getUserName(userId, users) {
    const user = users && users.find(x => x.id === userId)
    return user?.firstName + ' ' + user?.lastName
}


/** CONFIG HELPERS */
export const sandboxCompanies = ['rsi', '000-qbo-demo', '002dtools', 'bwzQ9exyHWxtBpKZgFam']
let qboSandboxURL = 'https://qbo-sandbox.tracknicity.com/'
// qboSandboxURL = 'http://localhost:8080/' //comment out

let qboURL = 'https://qbo.tracknicity.com/'
// qboURL = 'http://localhost:8080/' //comment out
export function getQboURL(company) {
    const URL = sandboxCompanies.includes(company.id) ? qboSandboxURL : qboURL
    return URL
}

export function getBraintreeConfig(company) {
    const authorization = sandboxCompanies.includes(company.id) ? 'sandbox_v2r2xg2y_gn8p4y4gpbg5hgqd' : 'production_ktkztktc_bwtn2jhynfbkmxgz'
    return authorization
}

export const isElectron = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf(' electron/') !== -1;
};