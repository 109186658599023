import React, { useEffect, useState } from "react";
import { StyleSheet, View, ActivityIndicator, FlatList } from "react-native";
import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { CustomInput } from "components/Inputs/CustomInput";
import { HistoryCard } from "components/Cards/HistoryCard";
import { PrimaryText } from "components/Common/PrimaryText";
import { useParts } from "context/PartsContext";
import { useCompany } from "context/CompanyContext";
import { getUserHistory } from "services/user-service";
import { useCustomers } from "context/CustomersContext";
import { useAuth } from "context/AuthContext";
import { usePersistState } from "context/usePersistState";

let subscription;

export function MyHistory() {

  const { parts, manufacturers } = useParts()
  const { locations, company } = useCompany()
  const { currentUser } = useAuth()
  const { customers } = useCustomers()

  const [history, setHistory] = useState(null)
  const [filtered, setFiltered] = usePersistState(
    "DASHBOARD_recentHistory",
    null
  );
  const [search, setSearch] = useState(null)
  const [loading, setLoading] = useState(true)

  //move to component
  useEffect(() => {
    if (currentUser && parts && locations && customers && manufacturers) {
      subscription = getUserHistory(company, { id: 'all' }).limit(30)
        .onSnapshot(querySnapshot => {
          const temp = []
          querySnapshot && querySnapshot.forEach(doc => {
            temp.push({
              id: doc.id,
              ...doc.data()
            })
          })
          gotHistory(temp)
        })
      return () => subscription && subscription()
    }
  }, [currentUser, parts, locations, customers, manufacturers])

  const gotHistory = (docs) => {
    docs.forEach(doc => {
      const part = parts.find(x => x.id === doc.partID)
      if (part) {
        doc.manufacturer = part.manufacturer
        doc.model = part.model
        if (part.parentRefID) {
          const parent = manufacturers.find(x => x.id === part.parentRefID)
          doc.manufacturer = parent ? parent.model : ''
          doc.lowercaseMfg = parent ? parent.model.toLowerCase() : ''
          part.manufacturer = parent ? parent.model : ''
          part.lowercaseMfg = parent ? parent.model.toLowerCase() : ''
        }
      }

      if (doc.fromClientId) {
        const from = customers.find(x => x.id === doc.fromClientId)
        if (from) {
          doc.from = from.fullName
        }
      } else if (doc.fromLocationId) {
        const from = locations.find(x => x.id === doc.fromLocationId)
        if (from) {
          doc.from = from?.name
        }
      }

      if (doc.toClientId) {
        const from = customers.find(x => x.id === doc?.toClientId)
        if (from) {
          doc.to = from.fullName
        }
      } else if (doc.toLocationId) {
        const from = locations.find(x => x.id === doc?.toLocationId)
        if (from) {
          doc.to = from?.name
        }
      }


    })
    setHistory(docs)
    // setFiltered(docs)
    // setLoading(false)
  }

  useEffect(() => {
    if (loading && filtered?.length) {
      setLoading(false)
    }
  }, [filtered])

  useEffect(() => {
    if (search) {
      const temp = history
        .filter(x =>
          x.to.toLowerCase().includes(search.toLowerCase())
          || x.from.toLowerCase().includes(search.toLowerCase())
          || x.model.toLowerCase().includes(search.toLowerCase())
          || x.manufacturer.toLowerCase().includes(search.toLowerCase())
        )

      setFiltered(temp)
    } else {
      setFiltered(history)
    }
  }, [search, history])



  return (
    <View style={styles.container}>
      <Header
        title={'Recent History'}
      // onBack={() => navigation.goBack()}
      />
      {loading ?
        <ActivityIndicator animating size={'large'} style={styles.activity} color={Colors.primary.lightBlue} />
        :
        <>
          <CustomInput
            placeholder={'Search History'}
            style={{ marginHorizontal: 12 }}
            onChange={setSearch}
            value={search}
          />
          <FlatList
            style={{ flex: 1 }}
            contentContainerStyle={{ backgroundColor: Colors.primary.white }}
            data={filtered}
            keyExtractor={item => item.id}
            renderItem={({ item, index }) => {
              return (
                <HistoryCard
                  item={item}
                  showItem
                />
              )
            }}
            ListEmptyComponent={<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <PrimaryText fontSize={20} fontWeight={400}>No history available</PrimaryText>
            </View>}
          />

        </>
      }

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  activity: {
    flex: 1,
    backgroundColor: Colors.primary.white
  }

});
