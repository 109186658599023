import { cloneDeep, findIndex } from "lodash";
import { firestore } from "utils/firebase";

export const getParts = (company) => {

    return firestore.collection('companies')
        .doc(company.id)
        .collection('parts')
    // .limit(10000)
    // .where('active', '==', true)

}

export const getPartById = (company, part) => {

    return firestore.collection('companies')
        .doc(company.id)
        .collection('parts')
        .doc(part.id)

    // .limit(10000)
    // .where('active', '==', true)

}

export const listenToParts = (company, initialSync, setInitialSync, parts, setParts, setManufacturers, setLoading) => {
    
    let query = firestore.collection('companies')
        .doc(company.id)
        .collection('parts')
    // .limit(10000)

    if (initialSync && parts) {
        query = firestore.collection('companies')
            .doc(company.id)
            .collection('parts')
            .where('lastModified', '>=', new Date(initialSync))
    }

    

    console.log('listen to parts', initialSync, parts?.length)

    return query
        .onSnapshot(documentSnapshot => {
            console.log('change in parts', documentSnapshot.docs.length)

            const temp = initialSync && parts ? [...parts] : []
            console.time('listenToParts')
            
            documentSnapshot && documentSnapshot.forEach(doc => {
                // var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
                // console.log(source, " data: ", doc.data());

                const data = doc.data()
                const part = {
                    id: doc.id,
                    ...data,
                    lowercaseModel: data.lowercaseModel.replaceAll("'", '‘').replaceAll('"', '“'),
                    lastModified: data.lastModified.toDate()
                }
                if (!part.qty) part.qty = 0
                // console.log('part', part.id)

                if (initialSync && temp) {
                    let find = temp.findIndex(x => x.id === part.id)
                    if (find > 0) {
                        temp[find] = part
                        // find = part
                        // console.log('find!', find)
                    } else {
                        temp.push(part)
                    }
                } else {
                    temp.push(part)
                }

            })
            const sorted = temp.sort((a, b) => a.model.toUpperCase() > b.model.toUpperCase() ? 1 : -1)
            // const unique = sorted.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            // console.log('setting parts from listener')
            setParts(sorted)
            
            setLoading(false)
            const mfg = sorted.filter((x) => !x.parentRefID);
            // console.log('mfg', mfg.length)
            const sortedMfg = mfg.sort((a, b) =>
                a.model.toUpperCase() > b.model.toUpperCase() ? 1 : -1
            );

            setManufacturers(sortedMfg);

            setInitialSync(new Date())
            
            console.timeEnd('listenToParts')
        }, e => {
            console.log('error listening to parts collection', e)
        })

}

export const getLocationParts = (company) => {
    // console.log(companyId, userId)
    return firestore.collection('companies')
        .doc(company.id)
        .collection('locationParts')

}

export const getServiceItems = async (company, setServiceItems) => {

    return firestore.collection('companies')
        .doc(company.id)
        .collection('serviceItems')
        .get().then(querySnapshot => {
            const temp = []
            querySnapshot.forEach(doc => {
                const item = {
                    id: doc.id,
                    ...doc.data()
                }
                temp.push(item)
            })
            setServiceItems(temp)
        })
}

export const getHistory = (company, item) => {
    return firestore.collection('companies')
        .doc(company.id)
        .collection('history')
        .where('partID', '==', item.id)
        .orderBy("date", "desc")
}

export const getSpecificStagedItems = async (company, part, locationItems, setLocationItems) => {
    // console.log('test', locationItems)
    return firestore
        .collection('companies')
        .doc(company.id)
        .collection('purchaseOrders')
        .where('deliveryComplete', '==', false)
        .where('receivedQuantity', '>', 0)
        .where('itemListId', '==', part.id)
        .get().then(querySnapshot => {
            const temp = []
            querySnapshot.forEach(doc => {
                const data = doc.data()
                const item = {
                    customerName: data.customerFullName,
                    customerId: data.customerId,
                    fromStock: data.fromStock,
                    locationID: doc.id,
                    locationQty: data.deliveredQuantity ? data.quantity - data.deliveredQuantity : data.quantity,
                    active: true,
                    itemListId: data.itemListId,
                    timeCreated: data.timeCreated,
                    deleted: data.deleted || false,
                }
                if (!data.deleted && item.locationQty > 0) temp.push(item)
            })
            if (part.bStockQty > 0) {
                const item = {
                    locationID: 'bstock',
                    locationQty: part.bStockQty,
                    active: true,
                }
                temp.push(item)
            }
            // temp.sort((a,b) => a.customerName.toUpperCase() > b.customerName.toUpperCase() ? 1 : -1)
            const concat = locationItems ? locationItems.concat(temp) : temp
            setLocationItems(concat)
        })
        .catch(e => {
            setLocationItems(locationItems)
            console.log('error getting specific staged items', e)
        })
}

export const getPartByUPC = async (company, code, setItem) => {
    firestore.collection('companies')
        .doc(company.id)
        .collection('parts')
        .where('upc', '==', code)
        .get().then(querySnapshot => {
            if (querySnapshot.docs.length > 0) {
                const doc = querySnapshot.docs[0]
                setItem({ id: doc.id, ...doc.data() })
            } else {
                setItem(null)
            }
        })
        .catch(e => {
            console.log('error getting item by upc', e)
        })
}

export const updatePart = (company, part, data, onSuccess) => {
    data.lastModified = new Date()
    firestore.collection('companies')
        .doc(company.id)
        .collection('parts')
        .doc(part.id)
        .update(data)
        .then(() => {
            onSuccess && onSuccess()
        })
        .catch(e => {
            console.log('error updating part', part)
        })
}

export const updateLocationPart = (company, partId, data) => {
    firestore.collection('companies')
        .doc(company.id)
        .collection('locationParts')
        .doc(partId)
        .update(data)
}

export const deleteLocationPart = (company, partId, data) => {
    firestore.collection('companies')
        .doc(company.id)
        .collection('locationParts')
        .doc(partId)
        .delete()
}